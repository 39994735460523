// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../../components/common/main-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import textile_white from "../../images/icons/textile_white.svg"
import intergrate_white from "../../images/icons/intergrate_white.svg"
import advance_white from "../../images/icons/advance_white.svg"

import textile_mint from "../../images/icons/textile_mint.svg"
import intergrate_mint from "../../images/icons/intergrate_mint.svg"
import advance_mint from "../../images/icons/advance_mint.svg"

//how it works icon

import charge_patch_icon from "../../images/feedback/How-it-works/Icons/charge_patch.svg"
import haptics_icon from "../../images/feedback/How-it-works/Icons/Haptics.svg"
import pathways_icon from "../../images/feedback/How-it-works/Icons/Pathways.svg"
import waterproof_icon from "../../images/feedback/How-it-works/Icons/waterproof.svg"

//platform feature icon

import Lightweight_pink from "../../images/feedback/Platform-feature-Icons/Lightweight_pink.svg"
import Lightweight_white from "../../images/feedback/Platform-feature-Icons/Lightweight_white.svg"
import machine_washable_pink from "../../images/feedback/Platform-feature-Icons/machine_washable_pink.svg"
import machine_washable_white from "../../images/feedback/Platform-feature-Icons/machine_washable_white.svg"

import next_to_skin_pink from "../../images/feedback/Platform-feature-Icons/next_to_skin_pink.svg"
import next_to_skin_white from "../../images/feedback/Platform-feature-Icons/next_to_skin_white.svg"
import Portfolio_white from "../../images/feedback/Platform-feature-Icons/Portfolio_white.svg"
import Portfolio_pink from "../../images/feedback/Platform-feature-Icons/Portfolio_pink.svg"

//import svgs for how it works icons
import intergration_svg from "../../images/visibility/How-it-works/Icons/Integration.svg"
import led_svg from "../../images/visibility/How-it-works/Icons/LED.svg"
import module_svg from "../../images/visibility/How-it-works/Icons/Module.svg"
import pathways_svg from "../../images/visibility/How-it-works/Icons/pathways.svg"
import FunctonalGreen from "../../images/thermal-regulation/icons/FunctionalGreen.svg"
import Machinewashablegreen from "../../images/thermal-regulation/icons/Machinewashablegreen.svg"
import optionalitygreen from "../..//images/thermal-regulation/icons/optionalitygreen.svg"
import Failsafegreen from "../../images/thermal-regulation/icons/Failsafegreen.svg"

//icons for how it works
import Flexible from "../../images/thermal-regulation/icons/how it works/flexible.svg"
import Panels from "../../images/thermal-regulation/icons/how it works/Panels.svg"
import Power from "../../images/thermal-regulation/icons/how it works/Power.svg"
import wiring from "../../images/thermal-regulation/icons/how it works/wiring.svg"

import Functonalwhite from "../../images/thermal-regulation/icons/Functionalwhite.svg"
// import Machinewashablegreen from "../../images/thermal-regulation/icons/Machinewashablegreen.svg"

import optionalitywhite from "../..//images/thermal-regulation/icons/optionalitywhite.svg"
import Machinewashablewhite from "../..//images/thermal-regulation/icons/Machinewashablewhite.svg"
import Failsafewhite from "../../images/thermal-regulation/icons/Failsafewhite.svg"

//import platform slider

const Feedback = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    hero: convertToBgImage(getImage(data.hero)),
    baner_odlo: convertToBgImage(getImage(data.baner_odlo)),

    led: convertToBgImage(getImage(data.led)),
    a: getImage(data.grid_1),
    grid_1: convertToBgImage(getImage(data.grid_1)),
    grid_2: convertToBgImage(getImage(data.grid_2)),
    grid_3: convertToBgImage(getImage(data.grid_3)),
    grid_4: convertToBgImage(getImage(data.grid_4)),
    grid_5: convertToBgImage(getImage(data.grid_5)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),

    //application images

    consumer_tech_feedback: convertToBgImage(getImage(data.app1)),
    Ferquency_Therapy: convertToBgImage(getImage(data.app2)),
    performance_feedback: convertToBgImage(getImage(data.app3)),
    workwear_feedback: convertToBgImage(getImage(data.app4)),
    // patented_img: convertToBgImage(getImage(data.app6)),

    //how it works images

    charge_patch: convertToBgImage(getImage(data.howItWorks1)),
    Haptics_2: convertToBgImage(getImage(data.howItWorks2)),
    Pathways: convertToBgImage(getImage(data.howItWorks3)),
    waterproof: convertToBgImage(getImage(data.howItWorks4)),

    //capability images
    configure_img: convertToBgImage(getImage(data.capability_img1)),
    research_img: convertToBgImage(getImage(data.capability_img2)),
    tech_spec_img: convertToBgImage(getImage(data.capability_img3)),

    //consumer img
    thinLight_img: convertToBgImage(getImage(data.app5)),
    tmpControl_img: convertToBgImage(getImage(data.app6)),

    //intergrated carousel images

    visiblity_img: convertToBgImage(getImage(data.inter_img1)),
    data_img: convertToBgImage(getImage(data.inter_img2)),
    feedback_img: convertToBgImage(getImage(data.inter_img3)),
    flexible_battery_img: convertToBgImage(getImage(data.inter_img4)),
    heating_img: convertToBgImage(getImage(data.inter_img5)),
    sensing_img: convertToBgImage(getImage(data.inter_img6)),
    textile_circuitry_img: convertToBgImage(getImage(data.inter_img7)),
    touch_textile_img: convertToBgImage(getImage(data.inter_img8)),

    //advance material images

    accoustic_img: convertToBgImage(getImage(data.advance_img1)),
    bio_mimikry_img: convertToBgImage(getImage(data.advance_img2)),
    memory_foam_img: convertToBgImage(getImage(data.advance_img3)),
    pu_foam_img: convertToBgImage(getImage(data.advance_img4)),
    silicon_print_img: convertToBgImage(getImage(data.advance_img5)),
    silicon_img: convertToBgImage(getImage(data.advance_img6)),

    // textile engineering images

    engineered_knit_img: convertToBgImage(getImage(data.textile_img1)),
    inj_mold_img: convertToBgImage(getImage(data.textile_img2)),
    narrow_width_img: convertToBgImage(getImage(data.textile_img3)),

    // platform images
    Configuration: convertToBgImage(getImage(data.platform1)),
    tech_spec: convertToBgImage(getImage(data.platform2)),

    // home images
    Banner_Feedback: convertToBgImage(getImage(data.Banner_Feedback)),
    Feedback_collection: convertToBgImage(getImage(data.Feedback_collection)),
    Discreet: convertToBgImage(getImage(data.Discreet)),
  }

  const [activePlatform, setActivePlatform] = useState(0)
  const [activePlatform2, setActivePlatform2] = useState(0)
  const [active, setActive] = useState(0)

  const slider = [
    {
      title: "Engineered Knit",
      content:
        "Leveraging Flat, Circular, and Warp knitting techniques to create complex structures, designs, and modulus.",
      image: images.engineered_knit_img,
      link: "/textile-engineering",
    },
    {
      title: "Narrow Width Fabrics",
      content:
        "Elasticated fabrics and elastomeric yarns engineered to achieve stretch and durability, as well as support complex design and construction.",
      image: images.narrow_width_img,
      link: "/textile-engineering",
    },
    {
      title: "Injection Molding",
      content:
        "A revolutionary form and padding technology for impact protection and the encasing of electronics.",
      image: images.inj_mold_img,
      link: "/textile-engineering",
    },
  ]

  const slider2 = [
    {
      title: "Visibility ",
      content:
        " Next-to-skin LED-based lighting that provides on-demand illumination ensuring visibility and safety, now with RGB capabilities.",
      image: images.visiblity_img,
      link: "/integrated-technologies",
    },
    {
      title: "Thermal Regulation",
      content:
        "Intelligent fabric-based thermal regulation, that can support a variety of functions ranging from microclimate regulation to pain management.",
      image: images.heating_img,
      link: "/integrated-technologies",
    },
    {
      title: "Sensing",
      content:
        "Seamlessly integrated biopotential and biomechanical sensors that allow for the collection of metrics ranging from heart rate to motion.",
      image: images.sensing_img,
      link: "/integrated-technologies",
    },
    {
      title: "Feedback",
      content:
        " Haptics, TENS, and EMS integrated into soft goods for tactile feedback, notification, and chronic pain management.",
      image: images.feedback_img,
      link: "/integrated-technologies",
    },
    {
      title: "Touch Textiles",
      content:
        " Pressure-sensitive textile constructions used to create interactive interfaces, replacing the need for traditional plastic components.",
      image: images.touch_textile_img,
      link: "/integrated-technologies",
    },
    {
      title: "Data Transfer",
      content:
        " Transfer of data and power across fabric substrates with limited flow and interference and low resistance, supporting over 4gbps and 40V at 5A.",
      image: images.data_img,
      link: "/integrated-technologies",
    },
    {
      title: "Flexible Battery",
      content:
        " Adaptable battery for a multitude of wearable uses. Flexible, washable, semi-solid electrolyte construction that serves as the industry’s solution to the growing design needs.",
      image: images.flexible_battery_img,
      link: "/integrated-technologies",
    },

    {
      title: "Textile Circuitry",
      content:
        " A collection of conductive pathways and inlays, interconnects and encapsulation to present technology in a seamless and invisible manner",
      image: images.textile_circuitry_img,
      link: "/integrated-technologies",
    },
  ]

  const slider3 = [
    {
      title: "Bio-mimicking material",
      content:
        "Thermo-responsive polymers and yarns, pine-cone biomimetic yarn for moisture management for improved comfort over extended wear times.",
      image: images.bio_mimikry_img,
      link: "/advanced-materials",
    },
    {
      title: "PU foam-based Injection Molding",
      content:
        "Replacing hard plastic components for flexibility, durability, and seamless integration of sensors",
      image: images.pu_foam_img,
      link: "/advanced-materials",
    },
    {
      title: "Acoustic dampening fabrics",
      content:
        "Certified Acoustic fabrics specifically designed for uninterrupted sound passthrough from materials to product.",
      image: images.accoustic_img,
      link: "/advanced-materials",
    },
    {
      title: "Memory Foam with PU Leather",
      content:
        "Internal and external fabric/leather lamination on foam provides superior comfort and flexibility. Memory foam built for high impact/low frequency.",
      image: images.memory_foam_img,
      link: "/advanced-materials",
    },

    {
      title: "Silicone Printing and Embellishments",
      content:
        "Patented Silicone Application Technology to enable targeted modification of fabric modulus using engineered functional prints for grip and comfort",
      image: images.silicon_print_img,
      link: "/advanced-materials",
    },
  ]

  const howItWorks = [
    {
      title: "Haptics",
      content:
        "A wide variety of haptic drivers in a range of form factor that can meet any application.",
      image: haptics_icon,
      cover: images.Haptics_2,
    },
    {
      title: "Pathways",
      content: "Wiring strategically placed for optimum comfort.",
      image: pathways_icon,
      cover: images.Pathways,
    },
    {
      title: "Charge patch",
      content: "Patches that distribute power for EMS and TENS.",
      image: charge_patch_icon,
      cover: images.charge_patch,
    },
    {
      title: "Waterproof",
      content: "Protected via silicone and TPU encapsulation",
      image: waterproof_icon,
      cover: images.waterproof,
    },
  ]

  function hello() {
    console.log(howItWorks[active])
  }

  const platform = [
    {
      title: "Technology Specifications",
      content:
        "Haptic motors are seamlessly integrated into soft goods, and then programmatically actuate in response to certain stimuli. Enabling feedback from any point on the device.   This same technology is also utilized for pain management in mobile electrical stimulation solutions.",
      content2: "",

      image: "../../images/brand/symbol-mint.png",
      cover: images.tech_spec,
    },
    {
      title: "Configurations",
      content:
        "Real-time feedback via audio and haptic technology EMS/TENS integrated garments and accessories for enhanced recovery and rehabilitation",
      image: "../../images/brand/symbol-mint.png",
      cover: images.Configuration,
    },
  ]

  const platformSlider = [
    {
      title: "Textile Engineering",
      image: textile_white,
      image_active: textile_mint,
    },
    {
      title: "Integrated Technologies",
      image: intergrate_white,
      image_active: intergrate_mint,
    },
    {
      title: "Advanced Material",
      image: advance_white,
      image_active: advance_mint,
    },
  ]

  return (
    <Layout>
      <Seo title="Feedback" />
      <section>
        <BackgroundImage
          {...images.Banner_Feedback}
          className="h-screen bg-dusk"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col ">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    Feedback that moves with you
                  </div>
                  <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Actuation for real time notifications, posture, and pain
                    management
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20 text-center sm:text-left">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-mint mb-4 uppercase text-center ">
                    VIBRATION AND MOVEMENT
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Tactile Response and Electrical Stimulation
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. Our platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-6xl leading-snug mt-4 text-dusk">
                    An altered state of mind around human interactivity and
                    recovery, made possible via Haptics, TENS and EMS
                    technologies.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>
                <Fade bottom cascade>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8 text-dusk">
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={machine_washable_pink}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={machine_washable_white}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Machine Washable
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Machine washable sensor systems
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={Portfolio_pink}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={Portfolio_white}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Portfolio
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        An offering of Haptics, EMS and TENS technology
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={Lightweight_pink}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={Lightweight_white}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Not bulky
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Lightweight feedback modules.
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={next_to_skin_pink}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={next_to_skin_white}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Next-to-Skin
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Electrodes and skin-touching components merged into
                        fabrics as a single unit.
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="mt-24">
              <Fade bottom cascade>
                <div>
                  <div className="text-4xl font-light text-dusk mb-4 sm:text-left text-center">
                    How it Works
                  </div>
                  <div className="text-2xl font-light text-dusk max-w-4xl sm:text-left text-center leading-snug">
                    Haptic motors are seamlessly integrated into soft goods, and
                    then programmatically actuate in response to certain
                    stimuli. Similarly, we have also developed mobile electrical
                    stimulation solutions for pain management and recovery.
                  </div>
                </div>
              </Fade>
              <div className="mt-14">
                <div className="flex flex-wrap items-center justify-center lg:gap-10 gap-5">
                  {howItWorks.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div>
                          <div
                            className={
                              active == index
                                ? "font-firs-semibold cursor-pointer text-mint sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-mint duration-200"
                                : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-transparent duration-200"
                            }
                            onClick={() => {
                              setActive(index)
                              hello()
                            }}
                          >
                            {item.title}
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
                <div className="mt-8">
                  <Fade bottom>
                    <div className="flex lg:flex-row flex-col sm:text-left text-center">
                      <BackgroundImage
                        {...howItWorks[active].cover}
                        className="w-full lg:w-2/5 py-44"
                      ></BackgroundImage>
                      <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
                        <div>
                          <div className="flex sm:justify-start justify-center">
                            <div>
                              <img
                                src={howItWorks[active].image}
                                width={80}
                                alt="Softmatter"
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-2xl font-light mt-8 text-white">
                          {howItWorks[active].title}
                        </div>
                        <div className="text-xl font-light mt-2 text-white max-w-lg">
                          {howItWorks[active].content}
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-salmon overflow-hidden">
          <div className="flex lg:flex-row flex-col">
            <div className="w-full lg:w-1/2 lg:hidden block">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Discreet}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2 w-full py-20 lg:py-0 pl-5 sm:pl-20 xl:pl-36 flex flex-col justify-center">
              <Fade bottom cascade>
                <div className="text-center sm:text-left pr-5 sm:pr-10 xl:pr-20">
                  <div className="text-4xl font-light mb-4 text-dusk ">
                    Discreet Feedback Technologies
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug text-white">
                    Our feedback suite contains three main technologies,
                    Haptics, TENS, and EMS. Our seamlessly integrated haptic
                    system is extremely versatile and has seen use-cases ranging
                    from vibration for posture correction, tactile feedback for
                    computer-based peripherals, and the creation of immersive XR
                    garments. Our work in TENS and EMS is focused around the
                    development of wearables for chronic pain management and
                    muscle recovery, in a soft, lightweight form factor.
                  </div>
                  {/* <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                    As this technology has matured, it has seen increased use in
                    thermotherapy wearables targeted at pain relief and
                    soreness.
                  </div> */}
                  {/* <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                    As this technology has matured, it has seen increased use in
                    thermotherapy wearables targeted at pain relief and
                    soreness.
                  </div> */}
                </div>
              </Fade>
            </div>
            <div className="w-full lg:w-1/2 lg:block hidden">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Discreet}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk">
          <div className=" pt-24">
            <Fade bottom cascade>
              <div className="text-white px-5">
                <div className="text-center text-2xl font-firs-thin uppercase  mb-4">
                  Our Feedback
                </div>
                <div className="text-center text-5xl md:text-6xl mb-4">
                  Applications
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  The potential of our feedback technology is limited only by
                  imagination. This versatile technology has use-cases ranging
                  from entertainment to posture correction.
                </div>
              </div>
            </Fade>
            <div className="mt-32 overflow-hidden">
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.Ferquency_Therapy}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Frequency Therapy
                      </div>
                      <div className="text-base max-w-xl">
                        Creating portable, wearable EMS and TENS systems for
                        recovery and pain management, alongside haptic
                        integrated healthcare monitoring wearables.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.Ferquency_Therapy}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.performance_feedback}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Performance
                      </div>
                      <div className="text-base max-w-xl">
                        Discreet haptic integration into activewear to act as a
                        notification system for key physical and proximity
                        alerts – built for safety and performance optimization.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.workwear_feedback}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Workwear
                      </div>
                      <div className="text-base max-w-xl">
                        Fabricating haptic based alert systems around posture,
                        proximity, and related metrics to keep workers safe in
                        real-time as well as provide actionable insight for
                        improvement.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.workwear_feedback}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.consumer_tech_feedback}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer technology
                      </div>
                      <div className="text-base max-w-xl">
                        Wearables built for immersion, using a programmable,
                        seamlessly integrated haptic system to recreate
                        sensations ranging from touch to impact.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div>
          <StaticImage src="../../images/feedback/Feedback_collection.png"></StaticImage>
        </div>
      </section>
      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Capabilities
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                Haptics, TENS, and EMS integrated into soft goods for tactile
                feedback, notification, and chronic pain management.
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-10 gap-5 flex-wrap">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            activePlatform2 == index
                              ? "font-firs-semibold cursor-pointer text-mint sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-mint duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-transparent duration-200"
                          }
                          onClick={() => {
                            setActivePlatform2(index)
                            hello()
                          }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform[activePlatform2].cover}
                      className="w-full lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-10 sm:p-14 flex flex-col justify-center">
                      <div className="text-2xl font-light text-white">
                        {platform[activePlatform2].title}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content2}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content3}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="mb-20">
              <Fade top>
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  TECHNOLOGY WOVEN IN
                </div>
              </Fade>
              <Fade bottom>
                <div className="text-center text-4xl text-white">
                  Explore All Platforms
                </div>
              </Fade>
            </div>
            <div className="flex lg:flex-row flex-col mb-72 lg:mb-44">
              <div className="w-full lg:w-4/12">
                <div className="flex gap-5 lg:gap-10 lg:flex-col justify-center flex-row">
                  {platformSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className=" text-white mb-14">
                          <div className="uppercase text-xl lg:block flex justify-center font-light mb-4">
                            {activePlatform == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className={
                              activePlatform == index
                                ? "mt-6  text-xs sm:text-xl text max-w-sm text-mint duration-100  lg:text-left text-center"
                                : "mt-6  text-xs sm:text-xl text max-w-sm duration-100 lg:text-left text-center"
                            }
                          >
                            <span
                              className={
                                activePlatform == index
                                  ? "pb-2 border-b-0 lg:border-b-2 border-mint duration-100 cursor-pointer"
                                  : "pb-2 duration-100 cursor-pointer hover:border-b-2 border-white"
                              }
                              onClick={() => setActivePlatform(index)}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
              <div className="w-full lg:w-7/12 pb-96 mb-32 sm:mb-24 lg:mb-0 lg:pb-24">
                <div className="text-white absolute w-full">
                  {activePlatform == 0 && (
                    <>
                      <MainSlider slider_array={slider} hover />
                    </>
                  )}
                  {activePlatform == 1 && (
                    <>
                      <MainSlider slider_array={slider2} hover />
                    </>
                  )}
                  {activePlatform == 2 && (
                    <>
                      <MainSlider slider_array={slider3} hover />
                    </>
                  )}

                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96 justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    width={180}
                    className="lg:w-auto w-20"
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-5xl sm:text-6xl mb-4">
                  How to Engage
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-mint  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query FeedbackImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    hero: file(relativePath: { eq: "thermal-regulation/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    baner_odlo: file(
      relativePath: { eq: "thermal-regulation/baner_odlo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "home/slider/slider_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "home/slider/slider_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_3: file(relativePath: { eq: "home/slider/slider_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    patented_awarded: file(
      relativePath: { eq: "visibility/Patentetd_awarded.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app1: file(
      relativePath: { eq: "feedback/Applications/consumer_tech_feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app2: file(
      relativePath: { eq: "feedback/Applications/Ferquency_Therapy.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app3: file(
      relativePath: { eq: "feedback/Applications/performance_feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app4: file(
      relativePath: { eq: "feedback/Applications/workwear_feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app5: file(
      relativePath: { eq: "feedback/Applications/workwear_feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    howItWorks1: file(
      relativePath: { eq: "feedback/How-it-works/charge_patch.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    howItWorks2: file(
      relativePath: { eq: "feedback/How-it-works/Haptics_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    howItWorks3: file(
      relativePath: { eq: "feedback/How-it-works/Pathways.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    howItWorks4: file(
      relativePath: { eq: "feedback/How-it-works/waterproof.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    platform1: file(
      relativePath: { eq: "feedback/Platform-Capabilities/Configuration.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    platform2: file(
      relativePath: {
        eq: "feedback/Platform-Capabilities/Tech_spec_feedback.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img1: file(
      relativePath: { eq: "platform-carousel/Integrated/Visibility.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img2: file(
      relativePath: { eq: "platform-carousel/Integrated/Data.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img3: file(
      relativePath: { eq: "platform-carousel/Integrated/Feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img4: file(
      relativePath: { eq: "platform-carousel/Integrated/Flexible_Battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img5: file(
      relativePath: { eq: "platform-carousel/Integrated/Heating.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img6: file(
      relativePath: { eq: "platform-carousel/Integrated/Sensing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img7: file(
      relativePath: { eq: "platform-carousel/Integrated/Textile_circuitry.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img8: file(
      relativePath: { eq: "platform-carousel/Integrated/Touch_textiles.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img1: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Accoustic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img2: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Bio_Mimikry_Material.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img3: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Memory_Foam_with_PU_Leather.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img4: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/PU_foam_based_Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img5: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Silicone_Printing.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img6: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Silicone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel1: file(
      relativePath: { eq: "Main_category_carousel/Advanced-Material.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel2: file(
      relativePath: { eq: "Main_category_carousel/Integrated.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel3: file(
      relativePath: { eq: "Main_category_carousel/Textile-Engineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img1: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Engineered_Knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img2: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img3: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Nawrrow_Width.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    Banner_Feedback: file(
      relativePath: { eq: "feedback/Banner_Feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    Discreet: file(relativePath: { eq: "feedback/Discreet.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    Feedback_collection: file(
      relativePath: { eq: "feedback/Feedback_collection.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Feedback_collection: file(
      relativePath: { eq: "feedback/Feedback_collection.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Feedback
